(function () {
	'use strict';
	angular
		.module('app')
		.controller('OptionsCtrl', [
			'$scope',
			'seedcodeCalendar',
			'shares',
			'utilities',
			OptionsCtrl,
		]);

	function OptionsCtrl($scope, seedcodeCalendar, shares, utilities) {
		//Load config from our model and watch for future updates
		$scope.calendar = {};
		$scope.calendar.config = seedcodeCalendar.get('config');

		$scope.calendar.view = seedcodeCalendar.get('view');

		$scope.manageShares = manageShares;

		function manageShares(create) {
			var share = {
				create: create,
				secondPanel: create ? true : false,
			};
			var popover = {
				id: 'manageShares',
				controller: 'SharesCtrl',
				container: $('body'),
				type: 'modal', // modal or popover
				width: 600,
				// positionX: e.pageX,
				// positionY: e.pageY,
				data: share,
				dataTitle: 'share',
				destroy: true,
				onShow: '',
				onShown: '',
				onHide: '',
				onHidden: '',
				show: true,
			};

			utilities.popover(
				popover,
				'<div ng-include="\'app/options/shares.html\'"></div>'
			);
		}
	}
})();
